import '../styles/components/ThanksMiniMessage.css'

const ThanksMiniMessage = () => {
  return(
    <div className="ThanksMiniMessage">
      <h3>Ya eres parte de la elite, pronto nos contactaremos contigo.</h3>
    </div>
  )
}

export default ThanksMiniMessage
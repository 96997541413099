import '../styles/containers/NotFound.css'

const NotFound = () => {
  return(
    <div className="not-found">
      <h1>404</h1>
      <p>esta pagina no fue encontrada</p>
    </div>
  )
}

export default NotFound